import React from 'react';
import PropTypes from 'prop-types';
import { Card, Container, Grid, Header, List } from 'semantic-ui-react';
import { IntroductionImage, PictoBiogas, PictoEolian, PictoQuarry, OccitanieImage, BpiImage } from '../../config/Images'
import './index.scss';
import { Link } from 'react-router-dom';

const Homepage = () => (
  <div className='homepage'>
    <Container className='custom-container'>
      <Grid className='introduction'>
        <Grid.Row>
          <Grid.Column computer={6} mobile={16} tablet={6} className='center aligned'>
            <IntroductionImage/>
          </Grid.Column>
          <Grid.Column computer={10} mobile={16} tablet={10}>
            <Container textAlign='center'>
              <h1>Évaluez le niveau d’acceptabilité sociétale de votre projet en quelques minutes!</h1>
              <p>Parce que les acteurs d’un territoire sont les premiers concernés par un projet d’aménagement, nous
                vous proposons d’évaluer les risques de contestations associées à l’image de l’activité, de la
                profession ou du projet lui-même.
                Ce test a pour objectif l’identification rapide des premières alertes de risques de contestation, aussi
                n’est-il pas nécessaire de prendre en compte toute la complexité d’un projet ni celle du territoire.</p>
            </Container>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid className='howto'>
        <Grid.Row centered>
          <Grid.Column mobile={16} tablet={8} computer={8}>
            <Container>
              <div className='title'>Ce test fournit</div>
              <List>
                <List.Item>
                  <List.Icon name='check'/>
                  <List.Content>Une évaluation générale du niveau d'acceptabilité de votre projet</List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name='check'/>
                  <List.Content>Une analyse des potentiels risques associés à l’image du projet ou du porteur de projet</List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name='check'/>
                  <List.Content>Des recommandations pour la prise en charge de ces risques</List.Content>
                </List.Item>
              </List>
            </Container>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={8}>
            <Container>
              <div className='title'>Comment l’utiliser ?</div>
              <ol>
                <li className="list-item">
                  Cliquez sur le secteur de votre choix pour commencer l’évaluation.
                </li>
                <li>
                  Complétez le formulaire.
                </li>
                <li>
                  Recevez automatiquement le niveau d’acceptabilité de votre projet.
                </li>
                <li>
                  Bénéficiez d’une approfondissement personnalisé de vos résultats.
                </li>
              </ol>
            </Container>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
    <div className='forms-content'>
      <div className='forms'>
        <Container className='custom-container'>
          <div className='title'>
            Remplissez notre formulaire afin d'évaluer votre projet
          </div>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <BusinessSegmentCard
                  name="Carrière"
                  type="carriere"
                  Icon={PictoQuarry}
                  description="Vous avez un projet d’ouverture, de renouvellement, d’extension de carrière.
                  Cliquez sur le bouton ci-dessous pour commencer le test."/>
              </Grid.Column>
              <Grid.Column>
                <BusinessSegmentCard
                  name="Biogaz"
                  type="biogaz"
                  Icon={PictoBiogas}
                  description="Vous avez un projet de construction d'une unité de Biogaz.
                      Cliquez du le bouton ci-dessous pour commencer le test."/>
              </Grid.Column>
              <Grid.Column>
                <BusinessSegmentCard
                  name="Eolien"
                  type="eolien"
                  Icon={PictoEolian}
                  description="Vous avez un projet de développement de parc éolien.
                      Cliquez du le bouton ci-dessous pour commencer le test."/>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    </div>
    <Container className="custom-container customer--quote">
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <blockquote>
              « J'avoue qu'en remplissant le questionnaire j'ai été surpris du peu de questions et interrogatif sur leur
              pertinence.
              Votre rendu tire des conclusions tout à fait intéressantes, notamment sur la crédibilité du
              projet/respect des engagements et sur les liens à maintenir avec les acteurs locaux. Le rendu est
              percutant ! »
            </blockquote>
            <p>
              <small>(Témoignage d’un porteur de projet de biogaz)</small>
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
    <Container className='custom-container'>
      <Grid className='description'>
        <Grid.Row>
          <Grid.Column computer={8} mobile={16} tablet={8} className='description-left'>
            <p>Cliquez sur l’une des trois entrées pour accéder au formulaire.
              A travers ce test, Acceptables Avenirs vous propose une <strong>évaluation générale du niveau
                d’acceptabilité</strong> de votre projet.</p>
            <p>L’évaluation est basée sur une modélisation des <strong>risques de contestations</strong>. Le modèle a été développé suite
              à une collaboration avec le laboratoire de recherche CERTOP de Toulouse et avec le soutien de la Région
              Occitanie et de l’ADEME.</p>
            <p><strong>Acceptables Avenirs</strong> vous accompagne pour toutes les phases de développement.</p>
            <p className="subtitle">En savoir plus</p>
            <p>Réalisée dès la phase de programmation, ce test vous permet d’ajuster et optimiser votre projet (en
              savoir plus : <a
                href="https://acceptablesavenirs.eu/"
                rel="noopener noreferrer"
                target='_blank'
                title='Acceptables Avenirs'>Acceptables Avenirs</a>)</p>
          </Grid.Column>
          <Grid.Column computer={8} mobile={16} tablet={8}>
            <Card>
              <Card.Content>
                <h2 className='title'>Acceptables Avenirs</h2>
                <p>Acceptables Avenirs vous accompagne pour favoriser l’acceptabilité sociétale de votre projet en
                  identifiant et qualifiant les risques de contestations et en vous aidant à les gérer.
                </p>
                <p>À travers son expertise et ses outils d’évaluation innovants, Acceptables Avenirs vous accompagne
                  pour limiter les coûts supplémentaires, les risques de dégradation de votre image et le désengagement
                  de vos partenaires, et pour mettre en place les conditions favorables à la légitimité de votre projet.</p>
                <div className='center'>
                  <a className='ui button'
                     href="https://acceptablesavenirs.eu/"
                     rel="noopener noreferrer"
                     target='_blank'
                     title='Acceptables Avenirs'>Voir le site</a>
                </div>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <div className="partnership aligned center"><span>Ils nous soutiennent</span> <OccitanieImage/> <BpiImage/></div>
    </Container>
  </div>
);

const BusinessSegmentCard = ({name, type, description, Icon}) => {
  return <Card>
    <Card.Content textAlign='center'>
      <div><Icon/></div>
      <Card.Header><h2>{name}</h2></Card.Header>
      <Card.Description>{description}</Card.Description>
      <Link className='ui button large' to={`/${type}`} title='Carrière'>Commencez maintenant !</Link>
    </Card.Content>
  </Card>;
};
BusinessSegmentCard.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  Icon: PropTypes.any,
};

export default Homepage;
