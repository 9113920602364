import React from 'react';
import { Container } from 'semantic-ui-react';

const LegalInformation = () => (
  <Container className='custom-container static-page'>
    <div className='title aligned center'>
      <h1>INFORMATIONS GÉNÉRALES</h1>
    </div>
    <div className='introduction aligned center'>
      <p>Les personnes qui accèdent aux informations mises à disposition par Acceptables Avenirs sur ce site Internet
        reconnaissent avoir pris connaissance des conditions d’utilisation et les acceptent.</p>
    </div>
    <p>Vous êtes connectés sur le site acceptablesavenirs.eu édité par la société <strong>Acceptables
      Avenirs :</strong></p>
    <p>Les informations présentées et transmises par l’intermédiaire de ce site sont susceptibles de modifications
      sans préavis.</p>
    <ul>
      <li>SAS au capital de 50 000 €</li>
      <li>Adresse : 20 rue Hermès – 31520 – Ramonville St Agne – France</li>
      <li>Responsable de la publication : Philippe Vervier – PDG</li>
      <li>Site internet : aastart.acceptablesavernirs.eu</li>
    </ul>
    <h2>HÉBERGEMENT</h2>
    <p>OVH, 2 rue Kellermann, BP 80157, 59053 ROUBAIX CEDEX 1, FRANCE</p>
    <h2>PROPRIÉTÉ INTELLECTUELLE</h2>
    <p>Les contenus figurant sur le site acceptablesavenirs.eu r sont la propriété de Acceptables Avenirs.</p>
    <p>Toute utilisation, reproduction ou représentation de tout et/ou de ses contenus n’est pas autorisée sans le
      consentement d’Acceptables Avenirs.</p>
    <h2>Mise en garde</h2>
    <p>Chaque utilisateur doit être conscient que la confidentialité des messages transitant sur le réseau internet
      n’est pas garantie et prendre les mesures appropriées pour protéger ses propres données et/ou logiciels de la
      contamination d’éventuels virus circulant sur le réseau internet.</p>
  </Container>
);

export default LegalInformation;
