import React from 'react';
import FormFields from './FormFields';
import {Container} from "semantic-ui-react";

const segment = 'Quarry';

const Quarry = () => (
  <Container className='custom-container form-content'>
    <h1 className='aligned center'>Carrière</h1>
    <FormFields
      segment={segment}
    />
  </Container>
);

export default Quarry;
