import React from 'react';
import FormFields from './FormFields';
import {Container} from "semantic-ui-react";

const segment = 'Biogas';

const Biogas = () => (
  <Container className='custom-container form-content'>
    <h1 className='aligned center'>Biogaz</h1>
    <FormFields
      segment={segment}
    />
  </Container>
);

export default Biogas;
