import React from 'react';
import {Link} from "react-router-dom";
import { LogoImage } from '../config/Images';

const Header = () => (
  <header className='ui container custom-container'>
    <div className='ui padded center aligned grid'>
      <div className='row'>
        <Link to="/"><LogoImage/></Link>
      </div>
    </div>
  </header>
);

export default Header;
