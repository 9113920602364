import React from 'react';
import FormFields from './FormFields';
import {Container} from "semantic-ui-react";

const segment = 'Eolian';

const Eolian = () => (
  <Container className='custom-container form-content'>
    <h1 className='aligned center'>Eolien</h1>
    <FormFields
      segment={segment}
    />
  </Container>
);

export default Eolian;
