import React from 'react'
import { Image } from 'semantic-ui-react'

export const LogoImage = () => <Image src='/images/accept-prediag.png' />;
export const IntroductionImage = () => <Image src='/images/illustration-intro.svg' fluid/>;
export const PictoBiogas = () => <Image src='/images/picto-gaz.svg' />;
export const PictoEolian = () => <Image src='/images/picto-eolien.svg' />;
export const PictoQuarry = () => <Image src='/images/picto-carriere.svg' />;
export const OccitanieImage = () => <Image src='/images/region-occitanie.png'/>;
export const BpiImage = () => <Image src='/images/bpi-france-occitanie.png'/>;
